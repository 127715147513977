<template>
<section class="content-wrapper">
    <div class="page-header">
      <h3 class="page-title">
        Panier
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);"></a></li>
          <li class="breadcrumb-item active" aria-current="page">Panier</li>
        </ol>
      </nav>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">
                      <div class="d-flex mb-4">
                        <h5 class="mr-2 font-weight-semibold border-right pr-2 mr-2">Détails du panier</h5>
                        <h5 class="font-weight-semibold">3</h5>
                      </div>
                    </div>
                    <div class="col-md-5 offset-md-2">
                      <b-input-group>
                            <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  
                  <div class="row mx-sm-0">
                    <div class="col-md-12 mb-5 pt-2 border px-0">
                      <div class="card rounded shadow-none">
                        <div class="wrapper border-top">
                          <div class="card-body">
                            <div class="row">
                              <div class="col d-flex">
                                <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                  <i class="mdi mdi-cart icon-sm my-0 "></i>
                                </div>
                                <div class="wrapper pl-3">
                                  <p class="mb-0 font-weight-medium text-muted">PANIER</p>
                                  <!-- <h2 class="font-weight-semibold mb-0">{{sumItem}}</h2> -->
                                  <h2 class="font-weight-semibold mb-0">{{cart}}</h2>
                                </div>
                              </div>
                              <div class="col d-flex">
                                <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                  <i class="mdi mdi-server-security icon-sm my-0 "></i>
                                </div>
                                <div class="wrapper pl-3">
                                  <p class="mb-0 font-weight-medium text-muted">TOTAL</p>
                                  <h2 class="font-weight-semibold mb-0">{{sumPrice}} €</h2>
                                </div>
                              </div>
                              <div class="col d-flex">
                                <button type="button" class="btn btn btn-gradient-primary btn-fw btn-secondary">valider</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>

                  <div class="row mx-sm-0">
                    <div class="col-md-12 mb-5 pt-2 border px-0" v-for="(item,i) in products" :key="i">
                      <div class="card rounded shadow-none">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-6 col-lg-5 d-lg-flex">
                              <div class="user-avatar mb-auto">
                                <img src="@/assets/images/import/actu-1.jpg" alt="profile image" class="profile-img img-lg rounded-circle">
                                <span class="edit-avatar-icon"><i class="mdi mdi-eye"></i></span>
                              </div>
                              <div class="wrapper pl-lg-4">
                                <div class="wrapper d-flex align-items-center">
                                  <h4 class="mb-0 font-weight-medium">{{item.name}}</h4>
                                  
                                <!-- <p>{{item.productQuantity}} {{item.name}}</p> -->
                                </div>
                                <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                                  <i class="mdi mdi-email-outline mr-2"></i>
                                  <p class="mb-0 text-muted">Miel</p>
                                </div>
                                <div class="wrapper d-flex align-items-start pt-3">
                                  <div class="badge badge-secondary text-dark mr-2">
                                    <i class="mdi mdi-check-circle-outline icon-sm"></i>
                                  </div>
                                  <div class="badge badge-secondary text-dark mr-2">
                                    <i class="mdi mdi-email-outline icon-sm"></i>
                                  </div>
                                  <div class="badge badge-secondary text-dark mr-2">
                                    <i class="mdi mdi-format-list-bulleted icon-sm"></i>
                                  </div>
                                  <div class="wrapper pl-2 d-none d-sm-block">
                                    <h4 class="mt-n1 mb-0 font-weight-medium">{{item.price}} €</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6 col-lg-5">
                              <p class="text-muted mt-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p>
                            </div>
                            <div class="col-sm-6 col-lg-2">
                              <div class="wrapper d-flex">
                                <input type="number" class="form-control mr-2" style="min-width:62px" v-model="productQuantity[i]" min="1">
                                <button type="button" class="btn btn-sm btn-danger mr-2" @click="removeProductToCart"><i class="mdi mdi-delete"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
export default {
    name:'cart',
    data () {
        return {
          users:[],
          search:'',
          productQuantity: []
        }
    },
    computed: {
     ...mapState (['products']),
     ...mapGetters (['cart']),
    sumPrice(){
        let TotalPrice=0
        // for ( const item of this.products) {
        //   // item.qty= this.productQuantity
        //   // this.productQuantity= item.qty
        //     TotalPrice= TotalPrice + item.price * item.productQuantity
        //     // TotalPrice= TotalPrice + item.price * this.productQuantity
        // }
        for (let i=0; i<this.products.length; i++) {
          TotalPrice= TotalPrice + this.products[i].price * this.productQuantity[i]
        }
        return TotalPrice
    },
    sumItem(){
        return this.cartItems.length
    },
    filter(){
      return this.users.filter((users) => {
        return users.firstname.match (this.search);
      })
    }
  },
  methods:{
    removeProductToCart () {
      this.$store.dispatch ('removeProductToCart')
    }
  },
  mounted() {
    for ( let i=0; i < this.products.length; i++) {
      this.productQuantity.push(1)
    }
    console.log('mounted result',this.products);
    console.log('produits',this.products);
  }
}
</script>